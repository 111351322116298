@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionFeatures {
    .Card {
        width: 100%;
        height: 100%;

        background-color: #f0f9ff;
        padding: 1rem;
        border-radius: 0.5rem;

        display: flex;
        flex-direction: row;
        align-items: flex-start;

        img {
            width: calc(min(18vw, 5rem));
            margin-right: 0.5rem;
            margin-top: 0.25rem;
            @include media(width, $small-screen-value: 5rem);
        }

        strong {
            margin-bottom: 0.25rem;
            display: inline-block;
            @include paragraph-17-bold-font;
        }

        p {
            color: rgba(#174350, 0.8);
            @include paragraph-14-font;
        }
    }
}
