@import 'fonts.scss';
@import 'interactive/link.scss';

@mixin nav-link {
    padding: 0 1rem;

    a {
        text-decoration: none;
        color: $gray-800;
        @include nav-text-font;
        @include link;
    }
}
