@import '../../utils/index.scss';
@import '../../mixins/index.scss';

@mixin open {
    opacity: 1;

    .Menu {
        transform: scale(1);
        opacity: 1;
    }
}

@mixin closed {
    opacity: 0;

    .Menu {
        transform: scale(0.75);
        opacity: 0;
    }
}

.MenuWrapper {
    position: fixed;
    top: var(--nav-height);
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &.Open {
        @include open;
    }
    &.Opening {
        @include closed;
    }
    &.Closed {
        display: none;
        @include closed;
    }
    &.Closing {
        @include closed;
    }

    @media (min-width: $small-screen) {
        display: none;
        @include closed;
    }

    --transition-duration: 0.2s;
    transition: all var(--transition-duration) ease-in-out;

    .Menu {
        width: 100%;
        max-width: 20rem;
        background-color: #ffffff;
        border-radius: 1rem;
        padding: 1.5rem;

        transition: all var(--transition-duration) ease-in-out;

        .Links {
            display: flex;
            justify-content: space-evenly;

            li {
                a {
                    text-decoration: none;
                    font-size: 1.25rem;
                    font-weight: 400;
                    color: $gray-800;
                    @include link;
                }
            }
        }

        .ButtonContainer {
            display: flex;
            width: 100%;
            margin-top: 1.5rem;

            .Button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
