@import '../mixins/index.scss';
@import '../utils/index.scss';

.ScreenshotSection {
    margin-top: 8rem;

    h1 {
        text-align: center;
        margin-bottom: 1.5rem;
        @include heading-h2-font;
    }

    p {
        text-align: center;
        margin-bottom: 1.5rem;
        color: $gray-500;
        @include paragraph-17-font;
    }

    .ScreenshotContent {
        width: 100%;
        img {
            width: 100%;
            border-radius: 0.5rem;
        }
    }
}
