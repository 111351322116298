@import '../../mixins/index.scss';
@import '../../utils/index.scss';

@mixin footer-font {
    color: $color-text-primary;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;

    @include media(line-height, $large-screen-value: 1.6785rem);
    @include media(font-size, $large-screen-value: 1rem, $extra-large-screen-value: 1.0625rem);
}

@mixin footer-link {
    padding: 0;
    @include media(padding, $small-screen-value: 0 1rem);

    a {
        text-decoration: none;
        @include footer-font;
        @include link;
    }
}

/* For non-index pages without our design */
body:not(.Index) {
    .Footer {
        margin-top: 2rem;
    }
}

.Footer {
    width: 100%;
    height: var(--footer-height);
    border-top: 1px solid $gray-100;
    padding: 1rem 0;

    display: flex;
    justify-content: center;

    .Content {
        width: var(--wrapper-width);
        height: 100%;
        margin: 0 var(--wrapper-margin-horizontal);

        display: grid;
        grid-template:
            'x a b y'
            'x a c y'
            'x a d y';
        grid-template-rows: 1fr auto auto 1fr;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        @include media(display, $small-screen-value: flex);

        .Logo {
            grid-area: b;
            @include footer-font;
        }

        .Links {
            grid-area: a;
            display: flex;
            flex-direction: column;

            li {
                @include footer-link;
            }
        }

        .EmailAndSocial {
            grid-area: c;
            @include footer-link;

            a {
                text-decoration: underline;
            }

            .Social {
                display: flex;
                align-items: center;
                margin-top: 0.5rem;

                .LinkedInIcon,
                .InstagramIcon {
                    margin: 0.3rem;

                    img {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }

                .LinkedInIcon {
                    margin-top: 0.1rem;
                }
            }
        }

        .AWS {
            grid-area: d;
            padding: 0.5rem 0;
        }
    }
}
