@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionCallToActionTop {
    padding-top: 2rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: normal;
    @include media(flex-direction, $large-screen-value: row);
    @include media(align-items, $large-screen-value: center);

    .Picture {
        position: relative;
        z-index: -1;
        flex: none;
        @include media(flex, $large-screen-value: 1);

        img {
            &.Big {
                display: none;
                position: relative;
                width: 40rem;
                left: 0rem;
                @include media(display, $large-screen-value: block);
                @include media(width, $extra-large-screen-value: 50rem);
            }
            &.Small {
                display: block;
                position: relative;
                width: 140%;
                left: -3%;
                @include media(display, $large-screen-value: none);
            }
        }
    }

    .BigHeader {
        margin-bottom: 1rem;
        @include heading-big-font;

        .Orange {
            color: $orange-600;
        }

        .EditableContainer {
            display: inline-block;
            position: relative;
            width: 0;
        }

        .Editable {
            display: inline-block;
            position: relative;
            margin: 0 0.5rem;

            --transition-duration: 0.1s;

            .Selection {
                background-color: rgba($orange-500, 0.22);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 50%;

                transition: var(--transition-duration) linear all;

                &.Hidden {
                    display: none;
                }

                &.Visible {
                    display: block;
                }
            }

            .Text:empty:before {
                content: '\200b';
            }

            .User {
                position: absolute;
                bottom: 100%;
                left: 100%;
                border: 1px solid $blue;
                border-radius: 0.675rem;
                background-color: #ffffff;
                color: $blue;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 0.75rem;
                padding: 0.125rem 0.375rem;

                transition: var(--transition-duration) linear all;

                @include media(font-size, $small-screen-value: 1rem);
                @include media(line-height, $small-screen-value: 1rem);
                @include media(padding, $small-screen-value: 0.25rem 0.5rem);

                &.Hidden {
                    opacity: 0;
                }

                &.Visible {
                    opacity: 1;
                }
            }

            .Cursor {
                position: absolute;
                transition: var(--transition-duration) linear all;
                top: 0;
                bottom: 0;
                left: 0%;
                width: 1px;
                background-color: #000000;

                &.Visible {
                    opacity: 1;
                }

                &.Hidden {
                    opacity: 0;
                }
            }
        }
    }

    .Content {
        margin-bottom: 1rem;

        .Buttons {
            display: flex;
            align-items: center;

            .Or {
                margin: 0.25rem 1rem;
            }
        }
    }

    .Description {
        margin-bottom: 2rem;
        width: auto;
        @include media(width, $large-screen-value: 27rem);

        p {
            line-height: 1.75rem;
            @include text-big-font;
        }
    }
}
