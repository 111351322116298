@import '../utils/breakpoints.scss';

@mixin media(
    $property,
    $small-screen-value: null,
    $medium-screen-value: null,
    $large-screen-value: null,
    $extra-large-screen-value: null
) {
    @if ($small-screen-value) {
        @media (min-width: $small-screen) {
            #{$property}: $small-screen-value;
        }
    }
    @if ($medium-screen-value) {
        @media (min-width: $medium-screen) {
            #{$property}: $medium-screen-value;
        }
    }
    @if ($large-screen-value) {
        @media (min-width: $large-screen) {
            #{$property}: $large-screen-value;
        }
    }
    @if ($extra-large-screen-value) {
        @media (min-width: $extra-large-screen) {
            #{$property}: $extra-large-screen-value;
        }
    }
}
