@import './colors.scss';
@import '../mixins/fonts.scss';

a.Button {
    text-decoration: none;
}

button.Button {
    border: none;
}

.Button {
    --normal-height: 2.75rem;
    --small-height: 2.25rem;

    @media (min-width: $small-screen) {
        --normal-height: 3.5rem;
        --small-height: 2.75rem;
    }

    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include button-font;

    transition: all 0.1s linear;

    &.Normal {
        background-color: $orange-500;
        color: white;
        height: var(--normal-height);
        border-radius: 0.75rem;
        padding: 0 1.5rem;

        &:hover {
            background-color: $orange-600;
        }

        &:active {
            background-color: $orange-700;
            transform: scale(0.95);
        }
    }

    &.NavButton {
        border: 1px solid $orange-500;
        background-color: white;
        color: $orange-500;
        height: var(--small-height);
        border-radius: 0.75rem;
        padding: 0 1rem;

        &:hover {
            background-color: $orange-50;
        }

        &:active {
            background-color: $orange-500;
            color: white;
            transform: scale(0.95);
        }
    }

    &.NavButtonDark {
        background-color: $orange-500;
        color: white;
        height: var(--small-height);
        border-radius: 0.5rem;
        padding: 0 1rem;

        &:hover {
            background-color: $orange-600;
        }

        &:active {
            background-color: $orange-700;
            transform: scale(0.95);
        }
    }

    &.FormButton {
        background-color: $orange-500;
        color: white;
        height: 2.75rem;
        border-radius: 0.75rem;
        padding: 0 1.5rem;

        &:hover {
            background-color: $orange-600;
        }

        &:active {
            background-color: $orange-700;
            transform: scale(0.95);
        }
    }

    &.ClaimOfferButton {
        color: $orange-500;
        height: 2.75rem;
        border-radius: 0.75rem;
        padding: 0 1.5rem;
        background-color: transparent;
        border: 1px solid $orange-500;

        &:hover:not(:disabled) {
            color: $orange-600;
            background-color: $orange-200;
        }

        &:active:not(:disabled) {
            color: $orange-600;
            background-color: $orange-200;
            transform: scale(0.95);
        }

        &:disabled {
            cursor: default;
            color: $gray-300;
            background-color: $gray-50;
            border-color: $gray-300;
        }
    }
}
