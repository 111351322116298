@import './fonts.scss';
@import './partials/section-calltoaction-top/section-calltoaction-top.scss';
@import './partials/section-calltoaction-bottom/section-calltoaction-bottom.scss';
@import './partials/nav/nav.scss';
@import './partials/footer/footer.scss';
@import './partials/screenshot-section.scss';
@import './partials/picture-with-text-section.scss';
@import './partials/section-features/section-features.scss';
@import './partials/section-mess/section-mess.scss';
@import './partials/section-pay-as-you-go/section-pay-as-you-go.scss';
@import './partials/section-estimate/section-estimate.scss';
@import './partials/section-estimate/card.scss';
@import './partials/section-tariffs/section-tariffs.scss';
@import './partials/section-tariffs/card.scss';
@import './partials/section-features/section-features.scss';
@import './partials/section-features/card.scss';
@import './partials/section-special-offer/section-special-offer.scss';
@import './partials/menu/menu.scss';

body,
html,
* {
    padding: 0;
    margin: 0;
    list-style-type: none;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

/* For non-index pages without our design */
body:not(.Index) {
    padding-left: 2rem;
    padding-right: 2rem;
}

body {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
    padding-top: var(--nav-height);
}

.Wrapper {
    /*border: 1px solid rgba(0,0,0,0.1);*/
    width: var(--wrapper-width);
    margin: 0 var(--wrapper-margin-horizontal);

    &.Narrow {
        width: var(--narrow-wrapper-width);
    }
}
