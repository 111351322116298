@import '../mixins/index.scss';
@import '../utils/index.scss';

.PictureWithTextSection {
    width: 100%;
    margin-top: 6rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .Picture {
    }

    .Text {
        h1 {
            margin: 0.5rem 0;
            @include heading-h2-font;
        }

        p {
            margin: 0.25rem 0;
            color: $gray-500;
            @include paragraph-17-font;
        }
    }
}
