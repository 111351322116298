@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionCallToActionBottom {
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @include media(flex-direction, $medium-screen-value: row);

    > img {
        width: 100%;
        max-width: 100%;
        @include media(
            max-width,
            $medium-screen-value: 40vw,
            $large-screen-value: 21rem,
            $extra-large-screen-value: 28rem
        );
    }

    .Card {
        padding: 1.5rem;
        background-color: #fff1d5;
        border-radius: 1rem;

        color: #7b3613;

        max-width: 38rem;

        margin: 1rem 0;

        @include media(padding, $small-screen-value: 0);
        @include media(
            padding,
            $small-screen-value: 1.75rem,
            $medium-screen-value: 2rem,
            $large-screen-value: 3rem
        );
        @include media(margin-left, $medium-screen-value: 0.5rem);

        h1 {
            margin-bottom: 1rem;
            @include heading-h3-font;
        }

        p {
            margin-bottom: 1rem;
            @include paragraph-17-font;
        }

        .Buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include media(flex-direction, $large-screen-value: row);

            .Or {
                display: inline-block;
                margin: 0.25rem 1rem;
            }
        }
    }
}
