@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionMess {
    flex-direction: column;
    color: $color-text-primary;
    @include media(flex-direction, $small-screen-value: row);

    .Picture {
        --x-shift: 0rem;
        --img-width: 100%;
        width: calc(var(--img-width) + var(--x-shift));
        @include media(--img-width, $small-screen-value: 40vw, $large-screen-value: 42.8125rem);

        img {
            width: var(--img-width);
            position: relative;
            left: var(--x-shift);
        }
    }

    .Text {
        margin-left: 1rem;
        @include media(margin-left, $small-screen-value: 2rem);

        h1 {
            @include heading-h2-font;
        }
    }
}
