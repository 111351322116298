@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionEstimate {
    margin-top: 8rem;

    background-color: #f7f9fd;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    padding: 1.5rem 1rem;
    @include media(padding, $small-screen-value: 2rem 2.5rem 2.5rem 2.5rem);

    h1 {
        margin-bottom: 1rem;
        @include media(margin-bottom, $small-screen-value: 2.5rem);
        @include heading-h2-font;
    }

    .Cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;

        > li {
            flex: 1;
            margin: 0;
            margin-bottom: 1rem;
            min-width: calc(100% - 1rem);
            @include media(margin, $medium-screen-value: 0 0.5rem);
            @include media(min-width, $medium-screen-value: auto);

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
