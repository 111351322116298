$orange-50: #fff9ed;
$orange-100: #fff1d5;
$orange-200: #fddeab;
$orange-300: #fcc675;
$orange-400: #faa23d;
$orange-500: #f78618;
$orange-600: #ee6e0e;
$orange-700: #c1520d;
$orange-800: #7b3613;

$gray-50: #f4f5f9;
$gray-100: #e0e1e9;
$gray-200: #c4c7cf;
$gray-300: #a9abb6;
$gray-400: #8a8e9b;
$gray-500: #6c6e79;
$gray-600: #484a54;
$gray-700: #2b2e38;
$gray-750: #272b35;
$gray-800: #191b23;

$blue: #1c88ec;

$color-text-primary: $gray-800;
$color-text-secondary: $gray-500;
