@mixin link($active-color: $orange-500) {
    display: inline-block;
    transition: all 0.1s linear;

    &:hover {
        color: $active-color;
    }

    &:active {
        transform: scale(0.95);
    }
}
