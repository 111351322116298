@import './media.scss';

/*
TODO: Use new version from below instead of these.
*/
@mixin heading-big-font {
    font-size: 2.75rem;
    font-weight: 700;
    line-height: 4rem;
    @include media(font-size, $small-screen-value: 4rem);
    @include media(line-height, $small-screen-value: 6rem);
}

@mixin heading-normal-font {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2.5rem;
}

@mixin text-big-font {
    font-size: 1.0625rem;
    font-weight: 400;
    @include media(font-size, $small-screen-value: 1.25rem);
}

@mixin text-normal-font {
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.375rem;
}

@mixin nav-text-font {
    font-size: 1.0625rem;
}

/*
New version of font sizes
*/
@mixin heading-h2-font {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2rem;

    @include media(font-size, $small-screen-value: 2.25rem);
    @include media(line-height, $small-screen-value: 2.5rem);
}

@mixin heading-h3-font {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;

    @include media(font-size, $small-screen-value: 2rem);
    @include media(line-height, $small-screen-value: 2.5rem);
}

@mixin heading-h4-font {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;

    @include media(font-size, $small-screen-value: 1.5rem);
}

@mixin heading-h5-font {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;

    @include media(font-size, $small-screen-value: 1.25rem);
}

@mixin paragraph-17-font {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.375rem;

    @include media(font-size, $small-screen-value: 1.0625rem);
}

@mixin paragraph-17-bold-font {
    @include paragraph-17-font;

    font-weight: 600;
}

@mixin paragraph-14-font {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25rem;

    @include media(font-size, $small-screen-value: 0.875rem);
}

@mixin paragraph-14-bold-font {
    @include paragraph-14-font;

    font-weight: 600;
}

@mixin button-font {
    @include paragraph-17-font;
}
