@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionFeatures {
    margin-top: 4rem;
    margin-bottom: 2rem;

    background-color: #e0f2fe;
    border-radius: 1rem;
    padding: 1.5rem 1rem;
    @include media(padding, $small-screen-value: 2.5rem 1.5rem);

    @include with-anchor;

    h1 {
        text-align: center;
        color: #0b4b6f;
        margin-bottom: 1rem;
        @include heading-h2-font;
    }

    .Cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;

        > li {
            flex: 1;
            min-width: calc(100% - 2rem);
            margin: 0.625rem;
            @include media(margin, $small-screen-value: 1rem);
            @include media(
                min-width,
                $medium-screen-value: calc(50% - 4rem),
                $large-screen-value: calc(33% - 6rem)
            );
        }

        /*
        .Card {
            background-color: #F0F9FF;
            border-radius: 0.5rem;
            padding: 1rem;
            margin: 1rem;
            width: 22rem;
        }
        */
    }
}
