@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionEstimate {
    .Card {
        height: 100%;
        @include card;

        h2 {
            margin-bottom: 1.5rem;
            text-align: center;
            @include heading-h4-font;
        }

        .Estimates {
            li {
                display: flex;
                margin-bottom: 0.5rem;

                .Bullet {
                    width: 1.5rem;
                }

                .Content {
                    .Name {
                        color: $color-text-secondary;
                        @include paragraph-14-font;
                    }

                    .Value {
                        color: $color-text-primary;
                        @include paragraph-17-font;
                    }
                }
            }
        }

        .Bill {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid #e0e1e9;
            text-align: center;

            .Description {
                color: $color-text-secondary;
                @include paragraph-14-font;
            }

            .Value {
                color: $color-text-primary;
                @include heading-h5-font;
            }
        }
    }
}
