:root {
    --nav-height: 4.75rem;
    /*
    --footer-height: 6rem;
    */
    --footer-height: auto;

    --wrapper-margin-horizontal: 1rem;
    --wrapper-width: calc(100% - var(--wrapper-margin-horizontal) * 2);
    --narrow-wrapper-width: var(--wrapper-width);

    @media (min-width: $small-screen) {
        --wrapper-width: 36rem;
        --wrapper-margin-horizontal: 0;
        --narrow-wrapper-width: calc(var(--wrapper-width) * 0.7);
    }

    @media (min-width: $medium-screen) {
        --wrapper-width: 44rem;
    }

    @media (min-width: $large-screen) {
        --wrapper-width: 60rem;
    }

    @media (min-width: $extra-large-screen) {
        --wrapper-width: 75rem;
    }
}
