@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionSpecialOffer {
    padding-top: 2.5rem;

    .Card {
        background-color: $orange-100;
        border-radius: 1rem;
        color: $orange-800;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include media(padding, $small-screen-value: 2rem);
        @include media(flex-direction, $extra-large-screen-value: row);
        @include media(align-items, $extra-large-screen-value: center);

        h1 {
            @include heading-h3-font;
        }

        p {
            @include paragraph-17-font;
        }

        .Message {
            padding: 0.5rem;
        }

        .Form {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: normal;
            flex: 1;
            /*width: 100%;*/
            @include media(justify-content, $medium-screen-value: flex-end);

            .Field {
                margin: 0.5rem;
                width: 100%;
                @include media(width, $small-screen-value: auto);

                .Input {
                    width: 100%;
                    @include media(width, $small-screen-value: 20rem);

                    &:disabled {
                        background: #f2f2f2;
                    }
                }
            }
        }

        .ThankYou {
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 1rem 4rem;

            p {
                text-align: center;
            }

            .SubHeader {
                font-size: 120%;
                font-weight: 700;
                margin-bottom: 0.5rem;
            }
        }
    }
}
