@import './colors.scss';

.Input {
    /*width: 20rem;*/
    height: 2.75rem;
    border-radius: 0.5rem;
    border: 1px solid $orange-200;
    background: white;

    font-size: 1.0625rem;
    font-weight: 400;

    padding: 0 1rem;
}
