@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionTariffs {
    margin-top: 2rem;

    background-color: #f7f9fd;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    padding: 1.5rem 1rem;
    @include media(padding, $small-screen-value: 2rem 2.5rem 2.5rem 2.5rem);

    .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h1 {
            @include heading-h3-font;
        }

        .Arrow {
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.75rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &.Flipped {
                img {
                    transform: rotate(180deg);
                }
            }

            img {
                transition: all 0.2s ease-in-out;
            }

            &:hover {
                transform: scale(1.25);
            }

            &:active {
                transform: scale(0.9);
            }
        }
    }

    .Cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height: 70rem;
        overflow-y: hidden;
        opacity: 1;
        transition: all 0.4s ease-in-out;
        margin-top: 1rem;
        @include media(margin-top, $small-screen-value: 2.5rem);

        &.Closing {
            max-height: 0;
            margin-top: 0;
            opacity: 0;
        }

        &.Opening {
            max-height: 0;
            margin-top: 0;
            opacity: 0;
        }

        &.Closed {
            display: none;
        }

        > li {
            flex: auto;
            width: 100%;
            margin: 0;
            margin-bottom: 1rem;
            @include media(flex, $large-screen-value: 1);
            @include media(width, $large-screen-value: auto);
            @include media(margin, $large-screen-value: 0 0.5rem);

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
