@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.SectionTariffs {
    .Card {
        @include card;

        h2 {
            margin-bottom: 1.5rem;
            text-align: center;
            @include heading-h5-font;
        }

        .Items {
            li {
                margin-bottom: 1rem;

                .Top {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @include media(flex-direction, $small-screen-value: row);

                    .Title {
                        color: $color-text-primary;
                        @include paragraph-17-font;

                        display: flex;
                        flex-direction: row;

                        .Bullet {
                            margin-right: 0.5rem;
                            padding-top: 0.125rem;
                            @include media(padding-top, $small-screen-value: 0rem);
                        }
                    }

                    .Price {
                        color: $color-text-secondary;
                        text-align: center;
                        @include paragraph-14-bold-font;
                    }
                }

                .Bottom {
                    color: $color-text-secondary;
                    @include paragraph-14-font;
                }
            }
        }
    }
}
