@mixin with-anchor {
    position: relative;

    .Anchor {
        width: 0;
        height: 0;
        visibility: hidden;
        position: absolute;
        top: calc(-1 * var(--nav-height) - 2rem);
    }
}
