@import '../../mixins/index.scss';
@import '../../utils/index.scss';

.Nav {
    height: var(--nav-height);
    border-bottom: 1px solid $gray-100;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    background-color: #ffffff;
    z-index: 1000;

    overflow-x: hidden;

    display: flex;
    justify-content: center;

    .Content {
        width: var(--wrapper-width);
        height: 100%;
        margin: 0 var(--wrapper-margin-horizontal);

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    .Links {
        display: none;
        @include media(display, $small-screen-value: flex);

        li {
            @include nav-link;
        }
    }

    .Buttons {
        display: none;
        @include media(display, $small-screen-value: flex);

        .SignIn {
            margin-right: 1.75rem;
        }
    }

    .Burger {
        display: flex;
        width: 3rem;
        height: 3rem;
        border-radius: 0.5rem;
        background: none;
        border: none;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media(display, $small-screen-value: none);

        .Line {
            background-color: $gray-800;
            height: 0.125rem;
            width: 1.25rem;
            border-radius: 0.125rem;
            margin: 0.25rem 0;
            transition: all 0.25s;
        }

        box-shadow: 0.125rem 0.125rem 1rem transparent inset;
        &:hover {
            box-shadow: 0.125rem 0.125rem 1rem $gray-50 inset;
            .Line {
                width: 1.5rem;
            }
        }
        &:active {
            box-shadow: 0.125rem 0.125rem 1rem $gray-100 inset;
            .Line {
                margin: 0.125rem 0;
            }
        }
        transition: all 0.25s;
    }
}
